import StickySidebar from'sticky-sidebar';

// StickySidebarのインスタンスを保持する変数
let stickySidebar;

function initializeStickySidebar() {
  // 該当要素がなければ何もしない
  const existChat = document.querySelector('.c-floating-chat');
  if (!existChat) return;

  // 既存のインスタンスがあれば破棄
  if (stickySidebar) {
    stickySidebar.destroy();
  }

  // 新しいインスタンスを作成
  stickySidebar = new StickySidebar('.c-floating-chat__js', {
    containerSelector: '.c-floating-chat',
    innerWrapperSelector: '.c-floating-chat__container',
    // 335は調整値
    topSpacing: window.innerHeight - 335,
  });
}

export function initFloatingChat() {
  // StickySidebarの初期化
  // スクロール時に読み込まないと表示が壊れるためtype: scroll を指定
  window.addEventListener('scroll', () => {
    // StickySidebarの初期化
    initializeStickySidebar();
  });

  // 画面のリサイズを検知
  window.addEventListener('resize', () => {
    initializeStickySidebar();
  });
}
